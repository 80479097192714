@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Rubik:wght@400&display=swap');

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.tap 
h1,h2,h3,h4,h5,h6,p,a  {
  @apply text-[#8CAF4B] dark:text-white;
  
}
.force-white-mode h1, .force-white-mode h2, .force-white-mode h3, .force-white-mode h4,
.force-white-mode h5, .force-white-mode h6,.force-white-mode p,.force-white-mode a{
  @apply dark:text-slate-900;
}
.force-white-mode a{
  @apply dark:text-blue-600
}
.phone-number-input *{
   background-color: #FFFFFF !important;
}
.force-white-mode input, .force-white-mode textarea, .force-white-mode select{
  @apply dark:bg-white
}
.force-white-mode input[type="radio"], .force-white-mode input[type="checkbox"]{
   @apply dark:bg-white bg-white
}
.phone-number-input input[type="tel"]:focus, .phone-number-input input[type="tel"]:hover,
.phone-number-input input[type="tel"]:active{
   border: none;
   outline: none;
}
.tap{
 font-family:"Cabin", sans-serif;
}

body {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --black: #000000;
  --orange: #FF7300;
  --ash: #707070;
  --light-green: #87AA62;;
  --yellow-variant: #FDB919 ;;
  --red: #F24444;
  --gold: #F3A327;
  --yellow: #FFC332;
  --white: #ffffff;
  --background: #E7F1F7;
}

.innerBlock {
  margin: auto;
  margin-top: 0;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 15px 55px 45px;
  border-radius: 15px;
  transition: all 0.3s;
}
.bodyStyles {
  padding: 50px 50px
}
.s_layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

ul {
  list-style-type: none;
  padding-left: unset !important;
}
.w-100{
  object-fit: contain !important;

}
.landing-page-image {
	/* width: 100vw; */
	height: 40rem;
	overflow: hidden;
  background: url('./images/assets/landing_header.JPG') no-repeat;
	background-position: center;
	background-size: cover;
  /* position: absolute; */
  margin-top: -2%;
}

.job-page-image{
   	/* width: 100vw; */
	height: 30rem;
	overflow: hidden;
  background: url('./images/assets/landing_header.JPG2.JPG') no-repeat;
	background-position: center;
	background-size: cover;
  /* position: absolute; */
}

@media only screen and (max-width: 414px) {  
 .job-page-image{
    height: 43rem;
 }


}
/* @font-face {
  font-family: "AvenirLight";
  src: url(./assests/fonts/AvenirLTStd-Roman.otf);
} */

@font-face {
  font-family: "Avenir";
  src: url(./assests/fonts/Avenir\ Black.ttf);
}
.listing-banner-image{
  height: 420px;
  width: 430px;
  background-image: url('./images/assets/image\ 2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.companies-banner-image{
  height: 420px;
  width: 430px;
  background-image: url('./images/assets/image\ 3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


.progress-custom {
  background-color: #87AA62;
  color: white;
}
input[type=radio] {
  width: 20px;
  height: 20px;
  margin:0;
  padding: 0;
}

.radio-text{
  align-items:'center';
  font-size:'8px';
  margin-bottom: "0";
  line-height:"10px"
}

input[type='radio']:checked:after {
  width: 23px;
  height: 23px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #87AA62;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid gray;
}

#agree0,#agree1,#agree2,#agree3,#agree4,#agree5,#agree6,#agree7,#agree8,#agree9{
  width: 0;
}
#disagree0,#disagree1,#disagree2,#disagree3,#disagree4,#disagree5,#disagree6,#disagree7,#disagree8,#disagree9{
  width: 0;
}

#agree0:checked:after,#agree1:checked:after,#agree2:checked:after,#agree3:checked:after,#agree4:checked:after,#agree5:checked:after,#agree6:checked:after,#agree7:checked:after,#agree8:checked:after,#agree9:checked:after{
  width: 0;
  height: 0;
  border: none
  
}
#disagree0:checked:after,#disagree1:checked:after,#disagree2:checked:after,#disagree3:checked:after,#disagree4:checked:after,#disagree5:checked:after,#disagree6:checked:after,#disagree7:checked:after,#disagree8:checked:after,#disagree9:checked:after{
  width: 0;
  height: 0;
  border: none
}

.nextButton{
  display: inline-block;
  color: #ffffff;
  background-color: var(--light-green);
  border-radius: 5px;
  padding: 10px;
  font-family: "Avenir";
  cursor: pointer;
border: none;
}
select .select{
  width: -webkit-fill-available;

}
input[type="text"] {
  color: black !important;
  }
select, input[type="text"]{
  width: -webkit-fill-available;
/* width: auto; */
/* height: 30px !important; */
/* color: black !important; */
}

.pad3 > .container-fluid{
  width: 65%;
}


.form-control::placeholder{
  color: black !important;
}

@font-face {
  font-family: "Avenir";
  src: url(./assests/fonts/Avenir\ Black.ttf);
}

@media only screen and (max-width: 768px) {  
  .innerBlock {
    width: unset;
    padding: 20px 25px 25px 25px;
  }
  .row {
    --bs-gutter-x: unset !important;
  }
  .bodyStyles {
    padding: 50px 30px;
  }
}
@media only screen and (max-width: 414px) {  

  .pad3 > .container-fluid{
    width: 100% !important;
  }
  .m-hidden{ display: none; }
  .button-md{
     font-size: 20px !important;     
  }
}
.nav-link:hover{
    color: #87AA62 !important;
}
#active-link{
  color: #87AA62 !important;
  text-decoration: underline;
}
/* Apply styles to the whole dropdown */

/* Apply styles to the dropdown options */
.dropdown-option {
  padding: 10px;
  background-color: white;
}

/* Apply styles on hover */
.dropdown-option:hover {
  background-color: green;
  color: white; /* Change text color for better contrast */
}
.why-choose-us{
  clip-path: polygon(0 0, 100% 9%, 100% 99%, 0% 100%);
}
@media only screen and (max-width: 414px) {  
  .why-choose-us{
    clip-path: polygon(0 1%, 100% 4%, 100% 100%, 33% 100%, 0 100%, 0% 50%);
    margin-top: -20px !important;
  }
  .button-md{
    @apply block w-full mx-auto rounded bg-armyGreen px-6 pb-2 pt-2.5 
    text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca]
     transition duration-150 ease-in-out hover:bg-green-600 focus:bg-green-600   
       focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
      focus:outline-none focus:ring-0 active:bg-green-700 
      active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] 
        
  }
}
.input-md{
   @apply py-[12px] text-sm pl-2 w-full border border-gray-200 
       rounded-lg mb-3 focus:border-armyGreen focus:outline-none;
}
.button-md{
  @apply block w-[100%] md:w-[50%]  mx-auto rounded bg-armyGreen px-6 py-4 md:py-3
  mb-3 md:mb-0 
  text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca]
   transition duration-150 ease-in-out hover:bg-green-600 focus:bg-green-600   
     focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
    focus:outline-none focus:ring-0 active:bg-green-700 
    active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]       
}
.job-batch-card{
  clip-path: polygon(0 1%, 98% 0, 54% 46%, 25% 71%, 0 91%, 0% 50%);
}
a.bi-whatsapp{  color: rgb(11, 153, 23); }
a.bi-twitter{ color: rgb(4, 196, 221); }
a.bi-instagram{ color: rgb(155, 29, 7); }
.bg-hero-img{
   background-image: url(./images/assets/bg2.jpg);
}
div.password-wrapper p{
  @apply text-xs text-red-500;
}
div.password-wrapper button{
  @apply -mt-1;
}
.job-description-container *{
   color: #000000;
}
.otp-form input{
  @apply py-3 text-sm md:text-lg font-bold text-primary text-center border-2 border-primary rounded-lg;
  width: 47px !important;
}
@media screen and (max-width:500px) {
  .otp-form input{
     width: 40px !important;
  }
}
